"use client";

import React, { useState } from "react";
import styles from "./LocalWebsiteSelector.module.scss";
import * as Select from "@radix-ui/react-select";
import Button from "../UI/Button/Button";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import { useI18n } from "@/i18n/client";
import { initBuilder } from "@/lib/builder/builder-utils";

type Region = { title: string; link: string; browserLanguageCode: string };
interface RegionSelectorProps {
	onMobile?: boolean;
	regionDropdowns: Region[];
}

const RegionWebsiteSelectorModal: React.FC<RegionSelectorProps> = ({
	onMobile,
	regionDropdowns,
}) => {
	const [selectedRegion, setSelectedRegion] = useState<string>("");
	const [formattedLink, setFormattedLink] = useState<string>("");

	const t = useI18n();

	initBuilder("global");

	const handleRegion = (region: string) => {
		setSelectedRegion(region);
		const formatString = region.search("//");
		if (formatString === -1) {
			return setFormattedLink(region);
		}
		setFormattedLink(region.split("//")[1]);
	};

	const handleDefaultValues = (code: any, type: string): string => {
		const filteredRegion = regionDropdowns.find(
			(region) => region.browserLanguageCode === code,
		);
		if (!filteredRegion) return ""; // Handle no matching region case

		switch (type) {
			case "title":
				return filteredRegion.title;
			case "url":
				return filteredRegion.link;
			default:
				return filteredRegion.link.split("//")[1];
		}
	};

	return (
		<section>
			<div
				className={`${styles.RegionWebsiteSelectorModal} ${
					onMobile ? styles.onMobile : ""
				}`}
			>
				{!onMobile && (
					<h3 className={styles.title}>
						{t("text.globalAccess")}
						<br />
						{t("text.localSupport")}
					</h3>
				)}
				<h4 className={`${styles.subtitle} ${onMobile ? styles.onMobile : ""}`}>
					{t("text.browseRegionalStore")}
				</h4>
				<label htmlFor='select'>{t("text.regionLanguage")}</label>
				<div id='select' className={styles.select}>
					<Select.Root
						onValueChange={(value) => handleRegion(value)}
						value={selectedRegion}
					>
						<Select.Trigger className={styles.trigger}>
							<Select.Value
								className={styles.value}
								placeholder={handleDefaultValues(
									regionDropdowns[0]?.browserLanguageCode,
									"title",
								)}
							/>
							<IconArrow
								direction='down'
								aria-labelledby='directional chevron pointing down'
							/>
						</Select.Trigger>
						<Select.Portal>
							<Select.Content className={styles.content}>
								<Select.Viewport className={styles.viewport}>
									{regionDropdowns?.map((dropdown) => (
										<Select.Item
											key={`${dropdown.title}-${dropdown.browserLanguageCode}`}
											value={dropdown.link}
											className={styles.item}
										>
											<Select.ItemText className={styles.SelectItemText}>
												{dropdown.title}
											</Select.ItemText>
										</Select.Item>
									))}
								</Select.Viewport>
							</Select.Content>
						</Select.Portal>
					</Select.Root>
				</div>
				<Button
					as='link'
					variant='primary'
					target='_blank'
					href={
						selectedRegion
							? selectedRegion
							: handleDefaultValues(
									regionDropdowns[0]?.browserLanguageCode,
									"url",
							  )
					}
				>
					{t("menu.orderOnline")}
				</Button>
				{regionDropdowns && (
					<p className={styles.link}>
						{t("text.onlineStore")}:&nbsp;&nbsp;
						<span>
							{formattedLink
								? formattedLink
								: handleDefaultValues(
										regionDropdowns[0]?.browserLanguageCode,
										"",
								  )}
						</span>
					</p>
				)}
			</div>
		</section>
	);
};

export default RegionWebsiteSelectorModal;
